import { AssistantBlockType, Storage as ImmutableStorage, VultronBlock } from "../CopilotSchemaImmutableTypes";
import { renderBlock } from "./utils";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { Empty } from "components/molecules/empty";
import { useInitWritingAssistant } from "./hooks";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { useEffect, useRef, useState } from "react";
import { ArrowDown } from "lucide-react";
import { useStorage } from "YJSProvider/createYJSContext";
import { fetchFollowUps } from "api/api";
import { FollowUpBlock } from "./FollowUpBlock";
import { setAssistantPrompt } from "store/reducers/writing-assistant/writingAssistantReducer";
import { motion, AnimatePresence } from "framer-motion";

type FollowUp = {
  title: string;
  prompt: string;
  confidence: number;
};

const WritingAssistantConversation = () => {
  const { streamState } = useAppSelector((store) => store.writingAssistant);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { currentUser } = useAppSelector((store) => store.auth);
  const { activeProject } = useAppSelector((root) => root.project);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const myConversation = useStorage(
    (root) => (root.writing_assistant as ImmutableStorage["writing_assistant"])?.[currentUser?.id || ""]?.conversation
  );
  const [followUps, setFollowUps] = useState<FollowUp[]>([]);
  const [isLoadingFollowUps, setIsLoadingFollowUps] = useState(false);
  const winThemes = useStorage((root) => root.win_themes) || [];
  const dispatch = useAppDispatch();

  useInitWritingAssistant(currentUser?.id, myConversation);
  const isEmpty = !myConversation?.length;
  const lastMessage = myConversation?.[myConversation.length - 1];
  const isLastMessageSuccessful =
    lastMessage?.type === AssistantBlockType.VultronBlock &&
    !(lastMessage as VultronBlock).error &&
    !isStreamingInProgress;

  useEffect(() => {
    if (autoScroll) {
      scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
      setAutoScroll(true);
    }
  }, [autoScroll, blockId, isStreamingInProgress, streamCopy, isLoadingFollowUps]);

  useEffect(() => {
    const getFollowUps = async () => {
      if (
        !myConversation ||
        myConversation.length === 0 ||
        !activeProject?.internal_contract.id ||
        !isLastMessageSuccessful
      )
        return;

      setIsLoadingFollowUps(true);
      try {
        const response = await fetchFollowUps({
          project_id: activeProject.internal_contract.id,
          past_messages: myConversation.map(({ body, type, updated_at, sources }) => ({
            content: body,
            is_assistant: type === AssistantBlockType.VultronBlock,
            sent_at: updated_at,
            sources: type === AssistantBlockType.VultronBlock ? sources : [], // Exclude 'sources' if it's the user's request
          })),
          win_themes: winThemes.map((theme) => theme.content),
        });

        setFollowUps(response.data.options);
      } catch (error) {
        console.error("Error fetching follow-ups:", error);
      } finally {
        setIsLoadingFollowUps(false);
      }
    };

    getFollowUps();
  }, [isStreamingInProgress, myConversation]);

  const handleFollowUpClick = (prompt: string) => {
    dispatch(setAssistantPrompt(prompt));

    setFollowUps((prevFollowUps) => prevFollowUps.filter((followUp) => followUp.prompt !== prompt));
  };

  return (
    <div className="relative flex w-full items-center justify-center flex-1 overflow-hidden">
      {isEmpty && !isStreamingInProgress ? (
        <Empty heading="How can I assist you today?" title="Send a message to get started" />
      ) : (
        <>
          {!autoScroll && (
            <button
              onClick={() => {
                if (blockId && myConversation?.[myConversation?.length - 1]?.id !== blockId) {
                  document
                    .getElementById(`assistant-block-${blockId}`)
                    ?.scrollIntoView({ block: "start", behavior: "smooth" });
                } else {
                  scrollRef.current?.scrollTo({
                    top: scrollRef.current?.scrollHeight + 5,
                    behavior: "smooth",
                  });
                }
              }}
              className="absolute flex justify-center items-center w-8 h-8 z-[1] rounded-full right-4 bottom-2 shadow-sharp-thin text-action text-[20px] bg-action-light backdrop-blur-[2px]"
            >
              {isStreamingInProgress ? (
                <TypingEffect
                  css={{
                    paddingLeft: 3,
                    paddingRight: 0,
                    paddingBottom: 6,
                    "& > div": tw`bg-action h-1 w-1 mr-[3px]`,
                  }}
                />
              ) : (
                <ArrowDown size={14} />
              )}
            </button>
          )}
          <div
            onScroll={(e) => {
              const shouldAutoScroll =
                e.currentTarget.scrollTop + 5 >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
              if (shouldAutoScroll !== autoScroll) setAutoScroll(shouldAutoScroll);
            }}
            ref={scrollRef}
            className="flex flex-col w-full items-start h-full gap-8 px-4 pt-5 pb-2 overflow-y-auto"
          >
            {myConversation?.map(renderBlock)}
            {!isStreamingInProgress && (
              <div className="w-full">
                {isLoadingFollowUps ? (
                  <div className="flex flex-row text-sm text-gray-500 p-2">
                    Generating follow-ups
                    <span className="loading-ellipsis" />
                  </div>
                ) : (
                  <AnimatePresence>
                    {followUps.length > 0 && (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="flex items-center justify-between w-full p-2 text-sm font-medium text-gray-700">
                          Follow-ups
                        </div>
                        <div className="flex flex-col gap-2 mt-2">
                          {followUps.map((followUp, index) => (
                            <FollowUpBlock
                              key={index}
                              followUp={followUp}
                              onClick={handleFollowUpClick}
                              onRemove={() => setFollowUps(followUps.filter((_, i) => i !== index))}
                            />
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                )}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WritingAssistantConversation;
