import React from "react";
import { X } from "lucide-react";

type FollowUp = {
  title: string;
  prompt: string;
  confidence: number;
};

type FollowUpBlockProps = {
  followUp: FollowUp;
  onClick: (prompt: string) => void;
  onRemove?: () => void;
};

export const FollowUpBlock: React.FC<FollowUpBlockProps> = ({ followUp, onClick, onRemove }) => {
  return (
    <div className="flex items-center justify-between w-full px-4 py-2 mb-2 bg-white border border-[#DBE0E4] rounded-[17px]">
      <button className="flex-grow text-left text-sm text-gray-700 truncate" onClick={() => onClick(followUp.prompt)}>
        {followUp.title}
      </button>
      {onRemove && (
        <button className="ml-2 text-gray-400 hover:text-gray-600" onClick={onRemove}>
          <X size={16} />
        </button>
      )}
    </div>
  );
};
